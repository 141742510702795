<template>
  <div class="my-1">
    <label class="text-sm mb-1 text-black font-medium" v-text="props.label"/>
    <div class="mt-1">
    <div class="flex items-stretch">
      <AvvSelect v-bind="$attrs" :placeholder="props.placeholder" :readonly="props.readonly" :value="props.modelValue" class="flex-grow" @select="option => emit(`update:modelValue`, option.getAttribute('value'))">
        <slot name="span"></slot>
        <div class="menu">
          <slot></slot>
        </div>
      </AvvSelect>
    </div>
    <span v-if="props.helpText" class="text-sm text-gray-500 mt-1" v-text="props.helpText"/>
    </div>
  </div>
</template>
<script lang="ts" setup>

const AvvSelect = window.AvvSelect;
const props = defineProps<{
  label: string
  helpText?: string
  modelValue: any
  placeholder?: any
  readonly?: boolean
}>();

const emit = defineEmits(["update:modelValue"])
</script>
<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({name: "DSelect"})
</script>