<template>
  <div :class="{'my-1': props.margin}">
    <label v-if="props.label" class="text-sm mb-1 text-black font-medium" v-text="props.label"/>
    <div class="flex items-center">
      <input :id="uuid" type="checkbox" :class="{checked: props.modelValue}" :value="props.modelValue" :checked="props.modelValue" :readonly="props.readonly" class="avv-button avv-checkbox" @input="onInputChange">
      <template v-if="tooltipMsg">
        <TopbarTooltip :message="tooltipMsg">
          <label class="text-sm mb-1 text-black" :for="uuid" v-text="props.helpText"/>
        </TopbarTooltip>
      </template>
      <template v-else>
        <label class="text-sm mb-1 text-black" :for="uuid" v-text="props.helpText"/>
      </template>
    </div>
  </div>
</template>
<script lang="ts" setup>

import {UUID} from "@avvoka/shared";
import TopbarTooltip from "../../../topbar/components/TopbarTooltip.vue"

const props = withDefaults(defineProps<{
  label?: string
  modelValue: boolean
  helpText?: string
  readonly?: boolean
  margin?: boolean
  tooltipMsg?: string
}>(), {
  margin: true
});

const emit = defineEmits(["update:model-value"])
const onInputChange = () => {
  if(props.readonly) return;
  emit('update:model-value', !props.modelValue)
}

const uuid = UUID.new()
</script>
<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({name: "DCheckbox"})
</script>